<template>
  <b-overlay :show="categoryBusy">
    <portlet title=" تۈر ئۇچۇرى تەھىرلەش ">
      <template v-slot:body>
        <category-form
          ref="category"
          action="edit"
          :model-id="categoryId"
          @loading="onLoading"
          @loaded="onLoaded"
          @updating="onLoading"
          @updated="onUpdated"
        />
      </template>
      <template v-slot:foot>
        <b-button id="submit" @click="handleSubmit" variant="primary">
          ساقلاش
        </b-button>
        <b-button class="ml-2" variant="danger" @click="onClickReset">
          بىكار قىلىش
        </b-button>
      </template>
    </portlet>
  </b-overlay>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import CategoryForm from "@v@/components/forms/CategoryForm";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import { debugConsole } from "@c@/helpers/helpers";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  export default {
    name: "index",
    components: { Portlet, CategoryForm },
    mixins: [ formBusyMixin, sweetAlertMixin ],
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: 'تۈر باشقۇرۇش', route: {name: 'category.home'}});
      BreadCrumbs.addBreadCrumb({title: 'تۈر ئۇچۇرى تەھرىرلەش'});
      if ( this.$refs.hasOwnProperty('category') ){
        this.form = this.$refs['category'];
      }
    },
    computed: {
      categoryId(){
        if ( this.$route.hasOwnProperty('params') )
          return this.$route.params.id;
      },
    },
    data(){
      return {
        categoryBusy: false,
        form: {}
      };
    },
    methods: {
      handleSubmit(){
        this.form.onSubmit();
      },
      onLoading(){
        this.showBusy('categoryBusy');
        debugConsole({prefix: 'category', message: 'onLoading'})
      },
      onLoaded({response, state}){
        this.hideBusy('categoryBusy');
        debugConsole({prefix: 'category', message: `onLoaded: ${state}`});
      },
      onUpdated({response, state}){
        this.hideBusy('categoryBusy');
        debugConsole({prefix: 'category', message: `onUpdated: ${state}`});
        if ( state === 'success' ){
          this.success({ message: 'تۈر ئۇچۇرى تەھرىرلەش مۇۋاپىقىيەتلىك بولدى.' });
        }
      },
      onClickReset(){
        this.$router.replace({name: 'category.home'});
      },
    }
  }
</script>

<style scoped>

</style>
