<template>
  <div class="region-home position-relative">
    <b-overlay :show="categoryBusy">
      <pagination :meta="categoriesMeta" place-holder="تۈر نامىنى كىرگۈزۈپ ئىزدەڭ" @change-page="onChangePage" @refresh="onRefresh">
        <b-button slot="action" :to="{name: 'category.store'}" class="btn btn-label-success btn-sm border-0">
          يېڭىدىن قوشۇش
        </b-button>
      </pagination>
      <b-row>
        <b-col v-for="category in categories" :key="`region_${category.id}`" md="3" sm="6">
          <portlet :title="category.title" body-fluid body-class="px-3 pb-3 pt-0" headNoBorder head-class="px-2" head-size="sm">
            <template slot="toolbar">
              <b-dropdown v-b-tooltip title="مەشغۇلات" toggle-class="text-decoration-none border-0 btn btn-clean btn-sm btn-icon btn-icon-md" no-caret>
                <template v-slot:button-content>
                  <i class="fa fa-ellipsis-v"></i>
                </template>
                <b-dropdown-text text-class="text-justify">
                  تۆۋەندىكى مەشغۇلاتلار مەزكۇر تۈردىكى بارلىق كىنولارغا بىراقلا تەسىر قىلىدۇ، شۇڭا ئويلىشىپ مەشغۇلات قىلغايسىز.
                </b-dropdown-text>
                <b-dropdown-divider ></b-dropdown-divider>
                <b-dropdown-text >
                  <b-dropdown-item @click="onClickVisible(category.id, 'in_home')">
                    <i class="fa fa-home"></i>   فىلىملەر باشبەتتە كۆرۈنسۇن
                  </b-dropdown-item>
                  <b-dropdown-item @click="onClickHidden(category.id, 'in_home')">
                    <i class="fa fa-house-damage"></i>   فىلىملەر باشبەتتە كۆرۈنمىسۇن
                  </b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item @click="onClickVisible(category.id, 'is_show')">
                    <i class="fa fa-lock-open"></i>   فىلىملەر كۆرۈنسۇن
                  </b-dropdown-item>
                  <b-dropdown-item @click="onClickHidden(category.id, 'is_show')">
                    <i class="fa fa-lock"></i>   فىلىملەر كۆرۈنمىسۇن
                  </b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item @click="onClickVisible(category.id, 'is_hidden')">
                    <i class="fa fa-eye-slash"></i>   فىلىملەر يوشۇرۇلسۇن
                  </b-dropdown-item>
                  <b-dropdown-item @click="onClickHidden(category.id, 'is_hidden')">
                    <i class="fa fa-eye"></i>   فىلىملەر يوشۇرۇلمىسۇن
                  </b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item @click="onClickVisible(category.id, 'is_recommend')">
                    <i class="fa fa-sort-amount-up"></i>   فىلىملەر تەۋسىيەلەنسۇن
                  </b-dropdown-item>
                  <b-dropdown-item @click="onClickHidden(category.id, 'is_recommend')">
                    <i class="fa fa-sort-amount-down"></i>   فىلىملەر تەۋسىيەلەنمىسۇن
                  </b-dropdown-item>
                </b-dropdown-text>
                <b-dropdown-divider />
                <b-dropdown-text text-class="text-justify">
                  تۆۋەندىكى مەشغۇلاتلار پەقەت مەزكۇر تۈرگە قارىتا قىلىنغان مەشغۇلاتلار.
                </b-dropdown-text>
                <b-dropdown-divider />
                <b-dropdown-text >
                  <b-checkbox switch v-model="category.is_show" @change="onChangeSwitch( category.id, 'is_show', $event )"> ھالىتى </b-checkbox>
                </b-dropdown-text>
                <b-dropdown-text >
                  <b-checkbox switch v-model="category.in_home" @change="onChangeSwitch( category.id, 'in_home', $event )"> باشبەتتە كۆرۈنەمدۇ </b-checkbox>
                </b-dropdown-text>
                <b-dropdown-divider />
                <b-dropdown-item :to="{name: 'category.edit', params: {id: category.id}}">
                  <i class="fa fa-info-circle"></i> تەھرىرلەش
                </b-dropdown-item>
                <b-dropdown-item @click="onClickDelete(category.id)">
                  <i class="fa fa-trash"></i> ئۆچۈرۈش
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template slot="body">
              <div class="kt-widget-item-container">
                <div class="kt-widget kt-widget--user-profile-2">
                  <div class="kt-widget__head" style="margin-top: -7px;">
                    <div class="kt-widget__media rounded" :style="{backgroundColor: category.title_color}">
                      <b-avatar rounded text="!" :src="category.icon_image_preview" size="50" />
                    </div>
                    <div class="kt-widget__info text-truncate">
                      <a class="kt-widget__username">
                        {{ category.other_title ? category.other_title : category.title }}
                      </a>
                    </div>
                  </div>
                  <div class="kt-widget__body">
                    <div class="kt-widget__item">
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">كىنو سانى: </span>
                        <span class="kt-widget__data"> {{ category.films_count }} پارچە </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <no-result :visible="!categories.length" background="warning" title="ئۇچۇر تېپىلمىدى" desc="مۇناسىۋەتلىك ئ‍ۇچۇر تېپىلمىدى، يېڭىدىن تۈر قوشۇڭ." >
        <template slot="action">
          <b-button :to="{name: 'category.store'}" class="btn-custom btn-bold btn-upper btn-font-sm  btn-warning">تۈر قوشۇش</b-button>
        </template>
      </no-result>
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import NoResult from "@v@/partials/content/NoResult";
  import categoryMixin from "@m@/category.mixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import pagination from "@v@/components/paginate";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import commonMethodMixin from "@m@/common/common.method.mixin";
  import msgBoxConfirmMixin from "@m@/common/msgBoxConfirmMixin";
  import {SET_REGION_ERROR} from "@/store/modules/region.module";
  import {SET_CATEGORY_ERROR} from "@/store/modules/category.module";

  export default {
    name: "index",
    components: { Portlet, pagination, NoResult },
    mixins: [categoryMixin, formBusyMixin, commonMethodMixin, msgBoxConfirmMixin],
    data(){
      return {
        categoryBusy: false,
        page: 1,
        title: ''
      };
    },
    computed: {},
    created() {
      this.getCategories();
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: 'تۈر باشقۇرۇش', route: {name: 'category.home'}});
      BreadCrumbs.addBreadCrumb({title: 'بارلىق تۈرلەر'});
    },
    methods: {
      onChangePage(e){
        let { title, page } = e;
        this.page = page; this.title = title;
        this.getCategories(page, title);
      },
      onRefresh(title){
        this.getCategories(1, title);
      },
      onChangeSwitch(id, column, value){
        this.switchColumn({
          resource: 'films/categories',
          id, column, mutationType: SET_CATEGORY_ERROR,
          begin: ()=>{ this.showBusy('categoryBusy') },
          after: ()=>{ this.hideBusy('categoryBusy') },
        });
      },
      onClickDelete(id){
        this.msgBoxConfirm({
          message: 'مەزكۇر تۈرنى ئۆچۈرسىڭىز ئەسلىگە قايتۇرالمايسىز، يەنىلا ئۆچۈرۈشنى جەزىملەشتۈرەمسىز ؟',
          confirmCallback: confirm=>{
            if ( confirm ){
              this.deleteModel({
                id, mutationType: SET_REGION_ERROR, resource: 'films/categories',
                begin: ()=>{ this.showBusy('categoryBusy')},
                after: ()=>{
                  let { title, page } = this;
                  this.getCategories(page, title);
                  this.hideBusy('categoryBusy');
                },
              })
            }
          }
        });
      },
      onClickVisible(id, column){
        this.setCategoryFilmsVisibleOrHidden(id, { column }, 1);
      },
      onClickHidden(id, column){
        this.setCategoryFilmsVisibleOrHidden(id, { column }, 0);
      },
    }
  }
</script>

<style scoped>
  .kt-widget__info {
    flex-grow: 1;
  }

  .kt-widget__img {
    max-width: 70px !important;
  }
</style>
