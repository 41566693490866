<template>
  <b-overlay :show="categoryBusy">
    <portlet title="يېڭىدىن تۈر قوشۇش">
      <template v-slot:body>
        <category-form
          ref="category"
          @storing="onStoring"
          @stored="onStored"
        />
      </template>
      <template v-slot:foot>
        <b-button id="submit" @click="handleSubmit" variant="primary">
          ساقلاش
        </b-button>
        <b-button class="ml-2" variant="danger">
          بىكار قىلىش
        </b-button>
      </template>
    </portlet>
  </b-overlay>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import CategoryForm from "@v@/components/forms/CategoryForm";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import { debugConsole } from "@c@/helpers/helpers";
  export default {
    name: "index",
    components: { Portlet, CategoryForm },
    mixins: [ formBusyMixin ],
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: 'تۈر باشقۇرۇش', route: {name: 'category.home'}});
      BreadCrumbs.addBreadCrumb({title: 'يېڭىدىن تۈر قوشۇش'});
      if ( this.$refs.hasOwnProperty('category') ){
        this.form = this.$refs['category'];
      }
    },
    data(){
      return {
        categoryBusy: false,
        form: {}
      };
    },
    methods: {
      handleSubmit(){
        this.form.onSubmit();
      },
      onStoring(){
        this.showBusy('categoryBusy');
        debugConsole({prefix: 'category', message: `onStored`});
      },
      onStored(state){
        this.hideBusy('categoryBusy');
        debugConsole({prefix: 'category', message: `onStored: ${state}`});
        this.$router.replace({name: 'category.home'});
      }
    }
  }
</script>

<style scoped>

</style>
